import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const home  = ()=> import('@/views/devices/DeviceInfo.vue')
const NotFound = ()=> import('@/views/NotFound')
const routes = [
    {
        path: '/',
        component: home,
    },

    {
        path: '*',
        component: NotFound
    }
]

const router = new VueRouter({
    // mode: 'history',
    routes
})

export default router
